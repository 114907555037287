<template>
  <div>
    <el-dialog
      class="scjb_Dialog Dialog_style1"
      :visible.sync="visible"
      :close-on-click-modal="false"
    >
      <div slot="title" class="mod-title">{{ title }}</div>
      <div class="cont">
        <ul>
          <li v-for="(row, index) in note" :key="index">
            <img
              src="@/assets/images/common/icon/red-dot.svg"
              alt=""
              v-if="row.type === 'error'"
              width="14"
            />
            <img
              src="@/assets/images/common/icon/blue-dot.svg"
              alt=""
              v-else
              width="14"
            />
            {{ row.text }}
          </li>
        </ul>
      </div>
      <div class="button-group">
        <el-button type="primary" round @click="confirmed()">{{
          confirm
        }}</el-button>
        <el-button round @click="canceled()">{{ cancel }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'confirm',
  props: {
    title: {
      type: String,
      default: ''
    },
    confirm: {
      type: String,
      default: '确定'
    },
    cancel: {
      type: String,
      default: '取消'
    }
  },
  data() {
    return {
      visible: false,
      data: {},
      note: [] //提示信息
    };
  },
  watch: {},
  mounted() {},
  methods: {
    show(note, data = {}) {
      this.note = note;
      this.data = data;
      this.visible = true;
    },
    confirmed() {
      this.$emit('must', this.data);
      this.visible = false;
    },
    canceled() {
      this.$emit('cancel');
      this.visible = false;
    }
  }
};
</script>
