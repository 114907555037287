<template>
  <div class="w-100 orientation-list">
    <div class="content">
      <el-card class="box-card min-h-600">
        <div class="mod-title first-title">定向信源信息列表</div>
        <div class="box-shadow"></div>
        <el-tabs v-model="activeName" @tab-click="handleClick" class="pt-10">
          <el-tab-pane label="定向监测" name="include">
            <el-button
              type="primary"
              round
              class="single-button mar-t-20"
              @click="createPlan('orientation-include')"
              >新增</el-button
            >
            <div class="mod-list lh-60 average-list" v-loading="loading">
              <ul v-if="dataList.length >= 1">
                <li>
                  <h3>
                    <span class="num-tit">序号</span>
                    <span class="tag">方案名称</span>
                    <span class="tag">创建日期</span>
                    <span class="tag">创建者</span>
                    <span class="tag">编辑</span>
                    <span class="tag">删除</span>
                  </h3>
                </li>
                <li v-for="(item, index) in dataList" :key="index" class="item">
                  <span class="num bg-555" v-if="index == 0">1</span>
                  <span class="num bg-689" v-else-if="index == 1">2</span>
                  <span class="num bg-8ea" v-else-if="index == 2">3</span>
                  <span class="num" v-else-if="index >= 3">{{
                    index + 1
                  }}</span>
                  <span class="tag">{{ item.name }}</span>
                  <span class="tag">{{ item.created }}</span>
                  <span class="tag">{{ item.createdby_name }}</span>
                  <span class="tag">
                    <i class="iconfont icon-bianji" @click="editPlan(item)"></i>
                  </span>
                  <span class="tag">
                    <i
                      class="iconfont icon-shanchu"
                      @click="handleClickDel(item)"
                    ></i>
                  </span>
                </li>
              </ul>

              <el-row class="empty" v-else>
                <div>
                  <img
                    src="@/assets/images/common/empty/dxxygl.svg"
                    alt="定向信源管理"
                    width="400"
                    class="img"
                    type="image/svg+xml"
                  />
                </div>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane label="定向排除" name="exclude">
            <el-button
              type="primary"
              round
              class="single-button mar-t-20"
              @click="createPlan('orientation-exclude')"
              >新增</el-button
            >
            <div class="mod-list lh-60 average-list" v-loading="loading">
              <ul v-if="dataList.length >= 1">
                <li>
                  <h3>
                    <span class="num-tit">序号</span>
                    <span class="tag">方案名称</span>
                    <span class="tag">创建日期</span>
                    <span class="tag">创建者</span>
                    <span class="tag">编辑</span>
                    <span class="tag">删除</span>
                  </h3>
                </li>
                <li v-for="(item, index) in dataList" :key="index" class="item">
                  <span class="num bg-555" v-if="index == 0">1</span>
                  <span class="num bg-689" v-else-if="index == 1">2</span>
                  <span class="num bg-8ea" v-else-if="index == 2">3</span>
                  <span class="num" v-else-if="index >= 3">{{
                    index + 1
                  }}</span>
                  <span class="tag">{{ item.name }}</span>
                  <span class="tag">{{ item.created }}</span>
                  <span class="tag">{{ item.createdby_name }}</span>
                  <span class="tag">
                    <i class="iconfont icon-bianji" @click="editPlan(item)"></i>
                  </span>
                  <span class="tag">
                    <i
                      class="iconfont icon-shanchu"
                      @click="handleClickDel(item)"
                    ></i>
                  </span>
                </li>
              </ul>

              <el-row class="empty" v-else>
                <div>
                  <img
                    src="@/assets/images/common/empty/dxxygl.svg"
                    alt="定向信源管理"
                    width="400"
                    class="img"
                    type="image/svg+xml"
                  />
                </div>
              </el-row>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-card>

      <confirm
        class="single-information"
        ref="confirmDel"
        title="删除方案"
        @must="del"
      />
    </div>
  </div>
</template>
<script type="application/javascript">
import { getGroupSourceList, delSourcePlan as delPlan } from '@/api/plan';
import confirm from '@components/common/confirm';
export default {
  name: 'orientation-list',
  data() {
    return {
      activeName: 'include',
      dataList: [],
      loading: false,
      total: 0
    };
  },
  components: {
    confirm
  },
  mounted() {
    this.$store.dispatch('getSourceList').then(res => {
      this.total = res.data.length;
    });

    if (this.$route.params.hasOwnProperty('index')) {
      if (['include', 'exclude'].indexOf(this.$route.params.index) !== -1) {
        this.activeName = this.$route.params.index;
      }
    }
    this.loadList(this.activeName);
  },
  watch: {
    activeName: function(val) {
      this.loadList(val);
    }
  },
  methods: {
    loadList(type) {
      if (['include', 'exclude'].indexOf(type) === -1) {
        this.$message.warning('加载定向信源类型错误');
        return false;
      }

      this.loading = true;
      getGroupSourceList({ type })
        .then(res => {
          if (res.data.state) {
            const { data } = res.data;
            this.dataList = data;
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
          this.$message.error('加载定向信源列表失败，服务错误');
          window.console.error(e);
        });
    },
    createPlan(name) {
      if (this.total >= 10) {
        this.$message.warning('定向方案已达上限，请联系相关人员');
        return false;
      }

      this.$router.push({ name: name, params: { path: 'orientation' } });
    },
    editPlan(plan) {
      this.$router.push({
        name: 'orientation-update',
        params: { data: JSON.stringify(plan), path: 'orientation' }
      });
    },
    handleClickDel(item) {
      const textBox = [{ type: 'error', text: '您将不能再查看此方案' }];
      this.$refs.confirmDel.show(textBox, { id: item.id });
    },
    del(data) {
      delPlan(data).then(res => {
        const { state, message, error } = res.data;
        if (state) {
          this.$message.success('删除成功');
          if (this.total >= 1) {
            this.total = this.total - 1;
          }
          this.loadList(this.activeName);
        } else {
          this.$message.error(error || '删除失败，请重试！');
        }
      });
    },
    handleClick(tab, event) {}
  }
};
</script>
<style scoped>
.orientation-list .first-title {
  margin: 3px 0 23px 40px;
}
.orientation-list >>> .el-tabs__nav-wrap::after {
  background: #f2f2f2;
}
.orientation-list >>> .el-tabs__content {
  box-shadow: none;
  padding-top: 0;
}
.orientation-list .num-tit {
  width: 100px;
}
.orientation-list .num {
  margin-right: 82px;
}
</style>
